import PageContent from "components/page/PageContent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { css } from "linaria";
import useIsMobile from "hooks/useIsMobile";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";

const headerWrapper = css`
  background: linear-gradient(270deg, #e3f8fb 0%, #cde1f8 100%);
  padding: 4rem 0;
  @media (max-width: 1024px) {
    display: grid;
    height: 100%;
    background-color: #97acc5;
    padding: 0;
  }
`;
const headerInner = css`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    display: grid;
    grid-area: 1/1;
    place-items: center;
    position: relative;
  }
`;
const headerLeft = css`
  flex-grow: 1;
  width: 50%;
  align-self: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const headerRight = css`
  flex-grow: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 3.2rem 0;
    background: var(--white);
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

const titleWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 90%;
  h1,
  h2 {
    color: var(--nuetral-900);
    font-weight: 900;
  }
  h2 {
    text-transform: uppercase;
  }
  @media (max-width: 1024px) {
    padding: 3.2rem 1.6rem;
    width: 100%;
    h1,
    h2 {
      color: var(--white);
      background-image: none;
      -webkit-background-clip: unset;
      background-clip: unset;
      -webkit-text-fill-color: unset;
    }
    p {
      color: var(--white);
    }
  }
`;

const headerBottom = css`
  background-color: var(--white);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  .text-container {
    max-width: 600px;
    p {
      margin-bottom: 1.6rem;
    }
  }
  h2 {
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: capitalize;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 1.6rem;
  }

  p strong {
    font-weight: normal;
  }
`;

const ResourceGuideHeader = ({ title, headerImage, formId, about }) => {
  const image = getImage(headerImage);
  const { isMobile } = useIsMobile(1025);
  const innerContent = (
    <div className={headerInner}>
      <div className={headerLeft}>
        <div className={titleWrapper}>
          <h2>Resource Guide</h2>
          <h1>{title}</h1>
          {isMobile ? <></> : about}
        </div>
      </div>
      <div className={headerRight}>
        <FormWrapper
          height="567px"
          width="584px"
          title="Download the Resource Guide"
        >
          <RhombusForm formId={formId} />
        </FormWrapper>
      </div>
      {isMobile ? (
        <div className={headerBottom}>
          <div className="text-container">
            <h2>About the guide</h2>
            {about}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
  if (isMobile) {
    return (
      <section className={headerWrapper}>
        <GatsbyImage
          image={image}
          alt={title}
          style={{
            gridArea: "1/1",
            pointerEvents: "none",
          }}
          objectPosition={"top"}
          objectFit="contain"
          loading="eager"
        />
        {innerContent}
      </section>
    );
  } else
    return (
      <section className={headerWrapper}>
        <PageContent>{innerContent}</PageContent>
      </section>
    );
};

export default ResourceGuideHeader;
