import React from "react";
import ResourceGuideHeader from "./ResourceGuideHeader";
import LearnSection from "./LearnSection";
import EducationReviews from "./EducationReviews";
import TrialBanner from "components/common/footer-banners/TrialBanner";

export const ResourceLandingPage = ({
  title,
  about,
  learn,
  formId,
  learnImage,
  headerImage,
}) => {
  return (
    <>
      <ResourceGuideHeader
        title={title}
        headerImage={headerImage}
        formId={formId}
        about={about}
      />
      <LearnSection learn={learn} learnImage={learnImage} />
      <EducationReviews />
      <TrialBanner />
    </>
  );
};

export default ResourceLandingPage;
