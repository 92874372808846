import React from "react";
import { Helmet } from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import ResourceLandingPage from "components/landing-pages/sled/ResourceLandingPage";
import { StaticQuery, graphql } from "gatsby";

export default function KempsGrantLandingPage() {
  const renderContent = data => {
    const learnList = [
      "An introduction to the Georgia School Grants",
      "Why a cloud-native solution is the best choice for campuses and school districts",
      "The benefit of using a unified platform with smart cameras, sensors, and integrations",
    ];

    const aboutCopy = (
      <>
        <p>
          Gain valuable information on the{" "}
          <strong>
            Georgia Department of Education's School Security Grant for FY2023
            (HB18).
          </strong>{" "}
          This bill prioritizes safe learning environments and has allocated
          over $115 million for security implementation, upkeep, or enhancements
          in schools.
        </p>
        <p>
          See how K-12 schools and universities nationwide are utilizing
          Rhombus' modern platform for proactive measures in improving school
          safety and operations.
        </p>
      </>
    );

    return (
      <GlobalLayout>
        <Helmet>
          <title>
            Rhombus - Enhancing School Safety in Georgia Resource Guide
          </title>
          <meta
            name="description"
            content="Learn how Rhombus can help Georgia Schools create safer environments for students and staff."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ResourceLandingPage
          title="Enhancing School Safety: Georgia School Grants and Rhombus' Innovative Security Solutions"
          about={aboutCopy}
          learn={learnList}
          formId="cd4678dc-28b6-4bc1-bc31-8bfc2c09c1c9"
          headerImage={data.mobileHeader}
          learnImage={data.about}
        />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      about: file(
        relativePath: {
          eq: "components/landing-pages/sled/img/HB18-learn-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      mobileHeader: file(
        relativePath: {
          eq: "components/landing-pages/sled/img/HB18-mobile-header-min-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
